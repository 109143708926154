import { jwtDecode } from 'jwt-decode'

interface TokenInfo {
    exp: number
}

export const isNotLoginLogin = () => {
    var expisold = false
    if (
        localStorage.getItem('tokensus') != null &&
        localStorage.getItem('tokensus') != '' &&
        localStorage.getItem('tokensus') != undefined &&
        localStorage.getItem('tokensus') != 'undefined'
    ) {
        const token: string = localStorage.getItem('tokensus') + ''
        const tokende = jwtDecode<TokenInfo>(token)
        if (Date.now() >= tokende.exp * 1000) {
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

export const idUser = () => {
    const user = localStorage.getItem('usersus')
    if (user) {
        return JSON.parse(user)['id']
    } else {
        return 0
    }
}

export const rolUser = () => {
    const user = localStorage.getItem('usersus')
    if (user) {
        return JSON.parse(user)['permissions'][0]
    } else {
        return 0
    }
}
