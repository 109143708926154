import CrudTable from '../../crud/CrudTable'
import {
    rentService as service,
    carService,
    billService,
} from '../../../services'
import FileDownloadIcon from '@mui/icons-material/PictureAsPdfOutlined'
import { Button } from '@mui/material'

const moment = require('moment')

const initFilter = {
    car: null,
    user: null,
    paymentState: null,
    from: null,
    to: null,
    rentalState: null,
}

export default function Rent() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'from',
            inputype: 'date',
            col: 4,
        },
        {
            type: 'input',
            name: 'from',
            inputype: 'to',
            col: 4,
        },
    ]

    const paintCell = {
        seguro: (value, object) => {
            return value ? (
                <>
                    <img src="/ic_secbig.png" style={{ width: '30px' }} />
                    <br />
                </>
            ) : (
                <></>
            )
        },
        km_real: (value, object) => {
            return <>{value ? value.toFixed(2) : 0} Km</>
        },
        paymentState: (value, object) => {
            if (value == 'NONE') {
                return <span style={{ color: 'black' }}>No realizado</span>
            } else if (value == 'PAYMENT') {
                return <span style={{ color: 'green' }}>Pagado</span>
            } else if (value == 'PARCIAL') {
                return <span style={{ color: 'orange' }}>Pago parcial</span>
            } else if (value == 'ERROR') {
                return <span style={{ color: 'red' }}>Error en el pago</span>
            } else {
                ;<></>
            }
        },
        rentalState: (value, object) => {
            if (value == 'RESERVED') {
                return <span style={{ color: 'black' }}>Reservado</span>
            } else if (value == 'CONFIRMRESERVED') {
                return (
                    <span style={{ color: 'black' }}>Reserva confirmada</span>
                )
            } else if (value == 'ASSIGNCAR') {
                return <span style={{ color: 'black' }}>Coche asignado</span>
            } else if (value == 'START') {
                return <span style={{ color: 'green' }}>Iniciado</span>
            } else if (value == 'END') {
                return <span style={{ color: 'green' }}>Finalizado</span>
            } else if (value == 'ISSUE') {
                return <span style={{ color: 'red' }}>Con incidencia</span>
            } else if (value == 'CANCEL') {
                return <span style={{ color: 'orange' }}>Cancelado</span>
            } else if (value == 'PENDING') {
                return <span style={{ color: 'orange' }}>Pendiente</span>
            } else if (value == 'PENDPAY') {
                return (
                    <span style={{ color: 'orange' }}>Pendiente de pago</span>
                )
            } else {
                ;<></>
            }
        },
        start: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        end: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        start_real: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        end_real: (value, object) => {
            if (value) {
                return (
                    <>{moment(new Date(value)).format('DD/MM/YYYY HH:mm:ss')}</>
                )
            }
        },
        billed: (value, object) => {
            if (object['idBill']) {
                return (
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#83c63b',
                            color: 'white',
                        }}
                        onClick={() => {
                            billService.print(
                                object['idBill'],
                                'Factura-Rent-' + object['id'] + '.pdf',
                                (data, error) => {}
                            )
                        }}
                    >
                        {' '}
                        <FileDownloadIcon />
                    </Button>
                )
            } else {
                return <></>
            }
        },
    }

    const conditionDelete = (rent) => {
        var isGenerate =
            rent &&
            rent.id &&
            rent.rentalState != '' &&
            rent.rentalState == 'CANCEL'
        return isGenerate
    }
    return (
        <CrudTable
            service={service}
            urledit={'/rent/edit/'}
            urlnew={'/rent/new/'}
            filter={initFilter}
            hiddenButton={true}
            deleteaction={false}
            conditionDelete={conditionDelete}
            editable={true}
            paintCell={paintCell}
            notexport={true}
            i18n={'rent'}
            fieldstable={[
                'driverActual.email|driverActual.name|driverActual.lastname',
                'car.brand|car.model|car.number',
                'seguro',
                'start',
                'end',
                'start_real',
                'end_real',
                'total',
                'km_real',
                'drivers.name',
            ]}
            labels={{
                'driverActual.email|driverActual.name|driverActual.lastname':
                    'Conductor',
                'car.brand|car.model|car.number': 'Vehículo',
            }}
            estructureForm={createEstructureFilter}
        />
    )
}
