import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Copyright from '../../commons/Copyright'
import styled from 'styled-components'
import { height } from '@mui/system'
import Card from '@mui/material/Card'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { userService as service, userService } from '../../../services'
import { useNavigate } from 'react-router-dom'

const loginData = {
    email: '',
    pass: '',
}

export default function Login() {
    const { t, i18n } = useTranslation()
    const [data, setData] = useState({ ...loginData })
    const [haveError, setHaveError] = useState(false)
    const navigate = useNavigate()

    const onChange = (event) => {
        let name = event.target.name
        let value = event.target.value

        setData((currentValueform) => ({ ...currentValueform, [name]: value }))
    }

    const onSubmit = (evt) => {
        evt.preventDefault()
        userService.login(data, (data, error) => {
            if (data) {
                debugger
                if (!data.user.permissions.includes('SUSCRIPTION')) {
                    setHaveError(true)
                } else {
                    localStorage.setItem('tokensus', data.token)
                    localStorage.setItem('usersus', JSON.stringify(data.user))
                    navigate('/dashboard')
                }
            } else {
                setHaveError(true)
            }
        })
        return false
    }

    return (
        <Main>
            <CardLongin>
                <img
                    src="/logoanimado.gif"
                    alt="mobify"
                    style={{ width: '80%' }}
                />
                <form method="post" onSubmit={onSubmit}>
                    <DivForm>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                        >
                            {t('login.title')}
                        </Typography>
                        <TextFieldForm
                            value={data.email}
                            id="email"
                            name="email"
                            required
                            variant="standard"
                            label={t('login.email')}
                            onChange={onChange}
                            type="email"
                        />
                        <br />
                        <TextFieldForm
                            value={data.pass}
                            id="email"
                            name="pass"
                            required
                            type="password"
                            variant="standard"
                            label={t('login.pass')}
                            onChange={onChange}
                        />
                        <br />
                        {haveError && (
                            <Typography
                                variant="body2"
                                color="error"
                                align="center"
                            >
                                {t('login.error')}
                            </Typography>
                        )}
                        <ButtonForm
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            {t('login.login')}
                        </ButtonForm>
                        <br />
                        <Link href="#" underline="hover" color="primary">
                            {t('login.recovery')}
                        </Link>
                        <br />
                    </DivForm>
                </form>
                <Copyright />
            </CardLongin>
        </Main>
    )
}

const CardLongin = styled(Card)`
    width: 600px;
    max-width: 85%;
    margin: auto;
    padding: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const TextFieldForm = styled(TextField)`
    margin-bottom: 20px;
`
const ButtonForm = styled(Button)`
    margin-top: 20px;
    margin-bottom: 20px;
`

const Main = styled.div`
    background-image: url('/backlogin.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
`

const ImgLogo = styled.img`
    width: 200px;
    max-width: 40%;
`

const DivForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 90%;
    row-gap: 10px;

    @media (max-width: 767px) {
        width: 90%;
    }
`
