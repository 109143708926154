import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CrudForm from '../../crud/CrudForm'
import { Link } from 'react-router-dom'
import {
    rentService as service,
    carService,
    rentalService,
} from '../../../services'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    Modal,
    Autocomplete,
    TextField,
    Grid,
} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify'
import CrudFormTabs from '../../crud/CrudFormTabs'
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps'
import { ControlRouteMap } from '../../utils/ControlRouteMap'

const initElement = {
    car: { id: -1, name: '' },
    carTypes: { id: -1, name: '' },
    prices: { id: -1, name: '' },
    user: { id: -1, name: '' },
    coupon: { id: -1, name: '' },
    zone: { id: -1, name: '' },
    startReserve: '',
    start: '',
    end: '',
    start_real: '',
    end_real: '',
    lat_point_start: 0.0,
    lng_point_start: 0.0,
    lat_point_end: 0.0,
    lng_point_end: 0.0,
    price: 0.0,
    total: 0.0,
    km_inlude: 0.0,
    km_real: 0.0,
    discount: 0.0,
    totalPay: 0.0,
    km_start: '',
    fuel_level_start: '',
    km_end: '',
    fuel_level_end: '',
    status_start: '',
    status_end: '',
    sharedPay: '',
    stree: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    rentalState: '',
    paymentState: '',
    pin: '',
    lines: [],
    tokenorder: '',
    paymentData: { id: -1, name: '' },
    seguro: false,
    minor25: false,
    minor3: false,
    tokenPayment: '',
    tokenPaymentFiance: '',
}

const initElementLine = {
    concept: '',
    price: 0.0,
    ammount: 0.0,
    total: 0.0,
}

export default function FormRent() {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1000,
        maxWidth: '80%',
        bgcolor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    type FormParms = {
        id: string
    }
    let { id } = useParams<FormParms>()
    const { t, i18n } = useTranslation()

    var stateForm = {
        openConfirm: false,
        openAddUser: false,
        useremail: '',
    }

    const deleteDriver = (user) => {
        service.deleteDriver(id, user.id, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                toast.success(error.mes, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const changeDriver = (user) => {
        service.changeDriver(id, user.id, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                toast.success(error.mes, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const fielddrives = (
        field,
        valueform,
        objectform,
        setSate,
        setValueField
    ) => {
        if (
            objectform['id'] &&
            objectform['id'] > 0 &&
            objectform['drivers'] &&
            objectform['drivers'].length > 0
        ) {
            return (
                <Grid container>
                    <Grid
                        xs={10}
                        style={{
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        }}
                    >
                        <b> Usuario </b>
                    </Grid>
                    <Grid
                        xs={2}
                        style={{
                            borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        }}
                    >
                        {' '}
                    </Grid>
                    {objectform['drivers'].map((driver) => (
                        <>
                            <Grid sm={10} xs={12} style={{ marginTop: '10px' }}>
                                {' '}
                                {driver['name']} <br /> {driver['email']}{' '}
                            </Grid>
                            <Grid sm={2} xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        width: '200px',
                                        marginTop: '10px',
                                    }}
                                    onClick={() => deleteDriver(driver)}
                                >
                                    {' '}
                                    Borrar
                                </Button>
                            </Grid>
                        </>
                    ))}
                </Grid>
            )
        } else {
            return <></>
        }
    }

    const fieldmap = (field, valueform, objectform, setSate, setValueField) => {
        if (
            objectform['id'] &&
            objectform['id'] > 0 &&
            objectform['lng_point_start'] &&
            objectform['lat_point_start']
        ) {
            return (
                <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                    <Map
                        center={{
                            lat: objectform['lat_point_start'],
                            lng: objectform['lng_point_start'],
                        }}
                        zoom={18}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        styles={[
                            {
                                featureType: 'poi',
                                stylers: [{ visibility: 'off' }],
                            },
                        ]}
                    >
                        <Marker
                            position={{
                                lat: objectform['lat_point_start'],
                                lng: objectform['lng_point_start'],
                            }}
                            title={objectform['id'] + 'start'}
                            icon={'/marker.png'}
                        />

                        {objectform['lat_point_end'] &&
                            objectform['lng_point_end'] && (
                                <Marker
                                    position={{
                                        lat: objectform['lat_point_end'],
                                        lng: objectform['lng_point_end'],
                                    }}
                                    title={objectform['id'] + 'end'}
                                    icon={'/markerFree.png'}
                                />
                            )}
                    </Map>

                    <ControlRouteMap route={objectform['routeUser']} />
                </APIProvider>
            )
        } else {
            return <></>
        }
    }

    const estructureform = [
        {
            type: 'input',
            name: 'user.name',
            colSpan: '50%',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'input',
            name: 'driverActual.name',
            colSpan: '50%',
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'start',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'input',
            name: 'end',
            inputtype: 'datetime-local',
            col: 3,
        },
        {
            type: 'title',
            name: 'titlevehicle',
            col: 12,
        },
        {
            type: 'input',
            name: 'car.number',
            readonly: true,
            col: 4,
        },
        {
            type: 'title',
            name: 'titlelocation',
            col: 12,
        },
        {
            type: 'input',
            name: 'street',
            col: 4,
        },
        {
            type: 'input',
            name: 'postalCode',
            col: 4,
        },
        {
            type: 'input',
            name: 'city',
            col: 4,
        },
        {
            type: 'title',
            name: 'othersinfo',
            col: 12,
        },
        {
            type: 'checkbox',
            name: 'seguro',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'minor25',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'checkbox',
            name: 'minor3',
            readonly: id && +id > 0,
            col: 3,
        },
        {
            type: 'autocomplete',
            name: 'rentalState',
            options: [
                { key: 'START', name: 'INICIADO' },
                { key: 'END', name: 'FINALIZADO' },
                { key: 'ISSUE', name: 'CON INCIDENCIA' },
                { key: 'CANCEL', name: 'CANCELADO' },
                { key: 'RESERVED', name: 'RESERVADO' },
                { key: 'CONFIRMRESERVED', name: 'RESERVA CONFIRMADA' },
                { key: 'ASSIGNCAR', name: 'COCHE ASIGNADO' },
            ],
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'start_real',
            inputtype: 'datetime-local',
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'end_real',
            inputtype: 'datetime-local',
            readonly: true,
            col: 3,
        },
        {
            type: 'input',
            name: 'km_real',
            readonly: true,
            col: 3,
        },
        {
            type: 'custom',
            name: 'route',
            custom: fieldmap,
            col: 4,
        },
        {
            type: 'title',
            name: 'titledrivers',
            col: 12,
        },
        {
            type: 'custom',
            name: 'drivers',
            custom: fielddrives,
            col: 12,
        },
    ]

    const recoveryElement = function (objectedit, element) {
        objectedit['car'] =
            element['car'] && element['car']['id']
                ? element['car']
                : { id: -1, name: '' }
        objectedit['carTypes'] =
            element['carTypes'] && element['carTypes']['id']
                ? element['carTypes']
                : { id: -1, name: '' }
        objectedit['prices'] =
            element['prices'] && element['prices']['id']
                ? element['prices']
                : { id: -1, name: '' }
        objectedit['user'] =
            element['user'] && element['user']['id']
                ? element['user']
                : { id: -1, name: '' }
        objectedit['coupon'] =
            element['coupon'] && element['coupon']['id']
                ? element['coupon']
                : { id: -1, name: '' }
        objectedit['zone'] =
            element['zone'] && element['zone']['id']
                ? element['zone']
                : { id: -1, name: '' }
        objectedit['startReserve'] = element['startReserve']
            ? element['startReserve']
            : ''

        objectedit['start'] = element['start'] ? element['start'] : ''
        objectedit['end'] = element['end'] ? element['end'] : ''
        objectedit['start_real'] = element['start_real']
            ? element['start_real']
            : ''
        objectedit['end_real'] = element['end_real'] ? element['end_real'] : ''
        objectedit['lat_point_start'] = element['lat_point_start']
            ? element['lat_point_start']
            : 0.0
        objectedit['lng_point_start'] = element['lng_point_start']
            ? element['lng_point_start']
            : 0.0
        objectedit['lat_point_end'] = element['lat_point_end']
            ? element['lat_point_end']
            : 0.0
        objectedit['lng_point_end'] = element['lng_point_end']
            ? element['lng_point_end']
            : 0.0
        objectedit['price'] = element['price'] ? element['price'] : 0.0
        objectedit['total'] = element['total'] ? element['total'] : 0.0
        objectedit['km_inlude'] = element['km_inlude']
            ? element['km_inlude']
            : 0.0
        objectedit['km_real'] = element['km_real'] ? element['km_real'] : 0.0
        objectedit['discount'] = element['discount'] ? element['discount'] : 0.0
        objectedit['totalPay'] = element['totalPay'] ? element['totalPay'] : 0.0
        objectedit['km_start'] = element['km_start'] ? element['km_start'] : ''
        objectedit['fuel_level_start'] = element['fuel_level_start']
            ? element['fuel_level_start']
            : ''
        objectedit['km_end'] = element['km_end'] ? element['km_end'] : ''
        objectedit['fuel_level_end'] = element['fuel_level_end']
            ? element['fuel_level_end']
            : ''
        objectedit['status_start'] = element['status_start']
            ? element['status_start']
            : ''
        objectedit['status_end'] = element['status_end']
            ? element['status_end']
            : ''
        objectedit['sharedPay'] = element['sharedPay']
            ? element['sharedPay']
            : ''
        objectedit['stree'] = element['stree'] ? element['stree'] : ''
        objectedit['city'] = element['city'] ? element['city'] : ''
        objectedit['state'] = element['state'] ? element['state'] : ''
        objectedit['country'] = element['country'] ? element['country'] : ''
        objectedit['postalCode'] = element['postalCode']
            ? element['postalCode']
            : ''
        objectedit['rentalState'] = element['rentalState']
            ? element['rentalState']
            : ''
        objectedit['paymentState'] = element['paymentState']
            ? element['paymentState']
            : ''
        objectedit['pin'] = element['pin'] ? element['pin'] : ''

        objectedit['lines'] = element['lines'] ? element['lines'] : []

        objectedit['startReserve'] = element['tokenorder']
            ? element['startReserve']
            : ''

        objectedit['paymentData'] =
            element['paymentData'] && element['paymentData']['id']
                ? element['zopaymentDatane']
                : { id: -1, name: '' }

        objectedit['seguro'] = element['seguro'] ? element['seguro'] : false
        objectedit['minor25'] = element['minor25'] ? element['minor25'] : false
        objectedit['minor3'] = element['minor3'] ? element['minor3'] : false
        objectedit['tokenPayment'] = element['tokenPayment']
            ? element['tokenPayment']
            : ''
        objectedit['tokenPaymentFiance'] = element['tokenPaymentFiance']
            ? element['tokenPaymentFiance']
            : ''

        return objectedit
    }

    const isValid = function (object) {
        if (!object['user'] || !object['user']['id']) {
            return t('rent.error.user')
        }

        return null
    }

    const sendConfirm = () => {
        service.changestate(id, 'CONFIRMRESERVED', (data, error) => {
            window.location.reload()
        })
    }
    const sendCar = (carSelect) => {
        if (carSelect && carSelect.key && carSelect.key > 0) {
            service.changecar(id, carSelect.key, (data, error) => {
                if (data && data.data) {
                    window.location.reload()
                } else {
                    toast.success(error.mes, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
        }
    }
    const sendConfirmUser = (userselected) => {
        service.addUser(id, userselected, (data, error) => {
            if (data && data.data) {
                window.location.reload()
            } else {
                toast.error(error.mes, {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const closeCar = (id) => {
        carService.closedoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const openCar = (id) => {
        carService.opendoor(id, (data, error) => {
            if (data && data.data) {
                toast.success('Se ha enviado la acción', {
                    position: toast.POSITION.TOP_CENTER,
                })
            }
        })
    }

    const moreActions = (getObject, state, saveState) => {
        var object = getObject()
        var isGenerate = id
        var isReserved = id && object.rentalState == 'RESERVED'
        var isNotFinish = id && object.rentalState != 'END'
        var createBill = id && !object.idBill && object.rentalState == 'END'
        var showBill = id && object.idBill && object.rentalState == 'END'
        var isStart = id && object.rentalState == 'START'
        var isEnd = id && object.rentalState == 'END'

        var isCar =
            id &&
            (object.rentalState == 'CONFIRMRESERVED' ||
                object.rentalState == 'ASSIGNCAR' ||
                object.rentalState == 'START')

        return (
            <>
                {isGenerate && (
                    <Button
                        component={Link}
                        to={'/rent/history/' + id}
                        variant="outlined"
                        color="secondary"
                        style={{ marginLeft: '30px' }}
                    >
                        {' '}
                        Ver historico conducción
                    </Button>
                )}
                <Button
                    variant="outlined"
                    color="primary"
                    style={{ marginLeft: '30px' }}
                    onClick={() => {
                        setStateInParent('openAddUser', true, saveState)
                    }}
                >
                    {' '}
                    Añadir usuario
                </Button>

                {isStart && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            openCar(object.car.id)
                        }}
                    >
                        {' '}
                        Abrir puertas
                    </Button>
                )}

                {isStart && (
                    <Button
                        variant="outlined"
                        color="warning"
                        style={{ marginLeft: '30px' }}
                        onClick={() => {
                            closeCar(object.car.id)
                        }}
                    >
                        {' '}
                        Cerrar puertas
                    </Button>
                )}
            </>
        )
    }

    const initeffect = (saveState) => {}

    const setStateInParent = (name, val, call) => {
        if (val) {
            call(name, val)
        } else {
            call(name, val)
        }
    }

    return (
        <>
            <CrudForm
                objectform={{ ...initElement }}
                estructureform={estructureform}
                i18n={'rent'}
                urledit={'/rent/edit/'}
                urlCancel={'/rent'}
                service={service}
                recoveryElement={recoveryElement}
                valid={isValid}
                moreActions={moreActions}
                initeffect={initeffect}
                tabs={[]}
                onlyRead={true}
                stateform={stateForm}
                haveInside={(state, saveState) => {
                    return (
                        <>
                            <Modal
                                open={state.openAddUser}
                                onClose={() =>
                                    setStateInParent(
                                        'openAddUser',
                                        false,
                                        saveState
                                    )
                                }
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                            >
                                <Box sx={style}>
                                    <div style={{ marginBottom: '30px' }}>
                                        ¿Ha que usuario desea asignarle el
                                        alquiler?
                                    </div>
                                    <div style={{ marginBottom: '30px' }}>
                                        <TextField
                                            variant="standard"
                                            sx={{ width: '100%' }}
                                            id={'email'}
                                            name={'email'}
                                            label={'Email'}
                                            value={state.useremail}
                                            onChange={(evt) => {
                                                setStateInParent(
                                                    'useremail',
                                                    evt.target.value,
                                                    saveState
                                                )
                                            }}
                                            type={'email'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '30px' }}
                                            onClick={() =>
                                                setStateInParent(
                                                    'openAddUser',
                                                    false,
                                                    saveState
                                                )
                                            }
                                        >
                                            {' '}
                                            Cancelar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                sendConfirmUser(
                                                    state['useremail']
                                                )
                                            }}
                                        >
                                            {' '}
                                            Asignar{' '}
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>
                        </>
                    )
                }}
            />
        </>
    )
}
