import CrudTable from '../../crud/CrudTable'
import { alertService as service } from '../../../services'

const initFilter = {
    name: null,
}

export default function Alerts() {
    const createEstructureFilter = [
        {
            type: 'input',
            name: 'title',
            col: 4,
        },
    ]

    return (
        <CrudTable
            service={service}
            urledit={'/alerts/edit/'}
            urlnew={'/alerts/new/'}
            filter={initFilter}
            editable={false}
            deleteaction={false}
            i18n={'alerts'}
            fieldstable={['createDate', 'title', 'user.email']}
            estructureForm={createEstructureFilter}
        />
    )
}
