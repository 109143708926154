import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom'
import Copyright from '../commons/Copyright'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from './Menu'
import MenuMui from '@mui/material/Menu'
import { useNavigate } from 'react-router-dom'
import { idUser } from '../../utils/session'
import TextField from '@mui/material/TextField'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Button } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { rolUser } from '../../utils/session'

const drawerWidth = 240

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const TextFieldHeader = styled(TextField)`
    margin-left: 2em;
    width: 300px;
    max-width: 50%;
    & input: {
        color: white;
    }
    & label.MuiFormLabel-colorPrimary {
        color: white !important;
    }
    & label.Mui-focused {
        color: white !important;
    }
    & input.MuiInput-input {
        color: white !important;
    }
    & .MuiInput-underline:after {
        border-bottom-color: white !important;
        transform: none;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: white;
        }
        &:hover fieldset {
            border-color: white;
        }
        &.Mui-focused fieldset {
            border-color: white;
        }
    }
`

const AutocompleteHEader = styled(Autocomplete)`
    margin-left: 2em;
    width: 300px;
    max-width: 50%;
    display: inline-block;

    & input: {
        color: white;
    }
    & label.MuiInputLabel-root {
        margin-top: -1em;
    }
    & label.MuiFormLabel-colorPrimary {
        color: white !important;
    }
    & .MuiInput-root.MuiInput-underline {
        margin-top: 0px;
    }
    & label.Mui-focused {
        color: white !important;
    }
    & input.MuiInput-input {
        color: white !important;
    }
    & .MuiInput-underline:after {
        border-bottom-color: white !important;
        transform: none;
    }
    & .MuiOutlinedInput-root {
        & fieldset {
            border-color: white;
        }
        &:hover fieldset {
            border-color: white;
        }
        &.Mui-focused fieldset {
            border-color: white;
        }
    }

    & svg {
        color: white;
    }
`

export default function Layout(props) {
    const t = props.t
    const [search, setSearch] = React.useState(props.search ? true : false)
    const [searchCombo, setSearchCombo] = React.useState(
        props.searchCombo ? true : false
    )
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(window.innerWidth > 768)
    const [width, setWidth] = React.useState<number>(window.innerWidth)
    const navigate = useNavigate()
    const [datacombo, setDataCombo] = React.useState([])
    const [salected, setSelected] = React.useState(null)
    const [urledit, setUrledit] = React.useState(
        props.urlEdit ? props.urlEdit : ''
    )

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    useEffect(() => {}, [])

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const closeSession = function () {
        localStorage.clear()
        navigate('/login')
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter' && props.code != '' && props.searchByCode) {
            props.searchByCode(props.code)
        } else {
            console.log('TECLA', e.key, 'CODIGO', props.code)
        }
    }

    const setCode = (code) => {
        props.setCode((currentState) => ({ ...currentState, ['code']: code }))
    }

    const getLabelOption = function (option) {
        if (option && option.name) {
            if (typeof option.name === 'string') {
                if (option.code) {
                    return option.code + ' - ' + option.name
                } else {
                    return option.name
                }
            } else {
                return ''
            }
        } else if (option && option.label && typeof option.label === 'string') {
            return option.label
        } else {
            return ''
        }
    }

    const handleChangeAutocomplete = (value) => {
        window.location.replace(urledit + value.key)
    }

    const searchCode = (e) => {
        if (e.nativeEvent.key == 'Enter') {
            e.preventDefault()
            var code = e.target.value
            var optionsfield = datacombo
            for (const key in optionsfield) {
                if (Object.prototype.hasOwnProperty.call(optionsfield, key)) {
                    const element = optionsfield[key]
                    if (element['code'] && element['code'] == code) {
                        handleChangeAutocomplete(element)
                        break
                    }
                }
            }
        }
    }

    const getAutocomplete = () => {
        return (
            <AutocompleteHEader
                id={'autoheader'}
                freeSolo={false}
                options={datacombo}
                getOptionLabel={getLabelOption}
                value={salected}
                onChange={(event, value) => handleChangeAutocomplete(value)}
                disableClearable={false}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        onKeyDown={(evt) => searchCode(evt)}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                        }}
                        autoComplete="off"
                        autoFocus={false}
                    />
                )}
            />
        )
    }

    useEffect(() => {
        if (searchCombo && props.service && props.service) {
            props.service.combo((data, error) => {
                if (data && data.data) {
                    setDataCombo(data.data)
                }
            })
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {!props.goback && (
                        <Button
                            style={{
                                paddingLeft: '1em',
                                paddingRight: '1em',
                                color: 'white',
                            }}
                            onClick={() => {
                                navigate(-1)
                            }}
                        >
                            <ArrowBackIcon />
                        </Button>
                    )}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        {props.title ? props.title : t('general.nameapp')}
                        {search && !searchCombo && (
                            <TextFieldHeader
                                variant="standard"
                                id={'code'}
                                name={'code'}
                                onKeyDown={_handleKeyDown}
                                label={props.label}
                                value={props.code}
                                onChange={(evt) => setCode(evt.target.value)}
                                type={'text'}
                                inputProps={{ autoComplete: 'off' }}
                                InputLabelProps={{ shrink: true }}
                                autoComplete="off"
                            />
                        )}
                        {searchCombo && getAutocomplete()}
                    </Typography>

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <MenuMui
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{ top: '2em' }}
                        >
                            <MenuItem>
                                <Link
                                    to={'/myaccount/' + idUser()}
                                    style={{
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                    replace
                                >
                                    {t('general.myprofile')}
                                </Link>
                            </MenuItem>
                            <MenuItem onClick={closeSession}>
                                {t('general.closession')}
                            </MenuItem>
                        </MenuMui>
                    </div>
                </Toolbar>
            </AppBar>
            <Menu open={open} handleDrawerClose={handleDrawerClose} t={t} />
            <Box component="main" sx={{ flexGrow: 1, p: 1, width: '100%' }}>
                <DrawerHeader />
                {props.children}
                <Box sx={{ marginTop: '3em' }}>
                    <Copyright />
                </Box>
            </Box>
            <div
                id="loadingpanel"
                data-calls={0}
                style={{
                    display: 'none',
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0,0,0,0.6)',
                    zIndex: '10000',
                    color: 'white',
                    fontWeight: 'bold',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <img src="/load2.gif" alt="load" style={{ width: '200px' }} />
            </div>
        </Box>
    )
}
