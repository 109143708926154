import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import {
    Assessment as AssessmentIcon,
    Build as BuildIcon,
} from '@mui/icons-material'
import PaymentIcon from '@mui/icons-material/Payment'
import ConstructionIcon from '@mui/icons-material/Construction'
import CarCrashIcon from '@mui/icons-material/CarCrash'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import EuroIcon from '@mui/icons-material/Euro'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import BusinessIcon from '@mui/icons-material/Business'
import DiscountIcon from '@mui/icons-material/Discount'
import RouterIcon from '@mui/icons-material/Router'
import MenuElement from './MenuItem'
import { rolUser } from '../../utils/session'
import MenuParent from './MenuParent'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import RouteIcon from '@mui/icons-material/Route'
import TravelExploreIcon from '@mui/icons-material/TravelExplore'
import PlaceIcon from '@mui/icons-material/Place'
import InventoryIcon from '@mui/icons-material/Inventory'
import CarRentalIcon from '@mui/icons-material/CarRental'
import MinorCrashIcon from '@mui/icons-material/MinorCrash'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AlertIcon from '@mui/icons-material/CrisisAlert'
import ImageIcon from '@mui/icons-material/Image'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import CarRepairIcon from '@mui/icons-material/CarRepair'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MapIcon from '@mui/icons-material/Map'
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const closedMixinXs = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `0px`,
})

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
        '& .MuiListItemText-root': {
            display: 'none',
        },
        '& .MuiListItem-root': {
            paddingLeft: '8px !important',
        },
        '& .MuiListItemIcon-root': {
            minWidth: '26px',
        },
    }),
    [theme.breakpoints.down('sm')]: {
        ...(!open && {
            ...closedMixinXs(theme),
            '& .MuiDrawer-paper': closedMixinXs(theme),
            '& .MuiListItemText-root': {
                display: 'none',
            },
        }),
    },
}))

export default function Menu(props) {
    const theme = useTheme()
    const [open, setOpen] = React.useState(props.open)
    const isAdmin = rolUser() === 'SUSCRIPTION'

    const t = props.t

    React.useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader>
                <IconButton onClick={props.handleDrawerClose}>
                    {theme.direction === 'rtl' ? (
                        <ChevronRightIcon />
                    ) : (
                        <ChevronLeftIcon />
                    )}
                </IconButton>
            </DrawerHeader>
            <Divider />
            {isAdmin && (
                <>
                    <MenuElement
                        url={'/dashboard'}
                        icon={<DashboardIcon />}
                        text={t('menu.dashboard')}
                    />
                    <MenuElement
                        url={'/rent'}
                        icon={<CarRentalIcon />}
                        text={t('menu.rent')}
                    />
                    <MenuElement
                        url={'/bill'}
                        icon={<ReceiptLongIcon />}
                        text={t('menu.bill')}
                    />
                </>
            )}
        </Drawer>
    )
}
