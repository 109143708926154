import BaseService from './_base.service';

const URL = "/appsuscriptor/rental";

export const rentService = {
    search,
    combo,
    page,
    pageFilter,
    one,
    create,
    edit,
    remove,
    removeLogic,
    login,
    recovery,
    logout,
    allFilterExcel,
    allFilterPdf,
    allFilterWord,
    changestate,
    changecar,
    generateBill,
    addline,
    end,
    addUser,
    changeDriver,
    deleteDriver,
    history
};

function search(offset,callback) {
    return BaseService.get(URL+"/all/", callback,false,false)
}

function combo(callback) {
    return BaseService.get(URL+"/combo/", callback, false,false)
}

function page(page,size,callback) {
    return BaseService.get(URL+"/page/"+page+"/"+size, callback, false,false)
}

function changestate(id,state,callback) {
    return BaseService.get(URL+"/change/state/"+id+"/"+state, callback, false,false)
}

function end(id,callback) {
    return BaseService.get(URL+"/end/"+id, callback, false,false)
}
function history(id,callback) {
    return BaseService.get(URL+"/history/"+id, callback, false,false)
}

function changecar(id,idCar,callback) {
    return BaseService.get(URL+"/change/car/"+id+"/"+idCar, callback, false,false)
}

function addUser(id,idUser,callback) {
    return BaseService.get(URL+"/add/user/"+id+"/"+idUser, callback, false,false)
}

function changeDriver(id,idUser,callback) {
    return BaseService.get(URL+"/change/user/"+id+"/"+idUser, callback, false,false)
}

function deleteDriver(id,idUser,callback) {
    return BaseService.get(URL+"/delete/user/"+id+"/"+idUser, callback, false,false)
}



function pageFilter(page,size,filter,callback) {
    return BaseService.post(URL+"/page/"+page+"/"+size, filter, callback, false,false)
}

function one(id,callback) {
    return BaseService.get(URL + "/one/"+id, callback);
}

function addline(id,line,callback) {
    debugger;
    let body = JSON.stringify(line)
    return BaseService.post(URL + "/add/line/"+id,body, callback);
}

function generateBill(id,callback) {
    return BaseService.get(URL + "/generate/bill/"+id, callback);
}

function create(bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.post(URL, body, callback, true)
}

function edit(id, bodyelement, callback) {
    let body = JSON.stringify(bodyelement)
    return BaseService.put(URL+"/"+id, body, callback, true)
}

function remove(id,callback) {
    return BaseService.delete(URL+"/"+id, callback);
}

function removeLogic(id,callback) {
    return BaseService.delete(URL+"/"+id+"/logic", callback);
}

function login(user, callback) {
    let body = JSON.stringify(user)
    return BaseService.post("/login", body, callback, true)
}

function recovery(email, callback) {

    // localStorage.setItem('user', JSON.stringify(user));
    let body = JSON.stringify({ email })
    return BaseService.post("/recoverypass", body, callback, true)
}


function logout() {
    localStorage.removeItem('user');
}

function allFilterPdf(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/pdf", filter,"rentals.pdf", callback,)
}


function allFilterExcel(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/excel", filter,"rentals.xlsx", callback,)
}


function allFilterWord(filter,callback) {
    let body = JSON.stringify(filter)
    return BaseService.postFile(URL+"/list/word", filter,"rentals.doc", callback,)
}
