import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { carService } from '../../../services'
import CrudEmpty from '../../crud/CrudEmpty'
import Filter from '../../filter/Filter'
import { APIProvider, Map, Marker, useMap } from '@vis.gl/react-google-maps'
import styled from 'styled-components'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DateRangeIcon from '@mui/icons-material/DateRange'
import TodayIcon from '@mui/icons-material/Today'
import BoltIcon from '@mui/icons-material/Bolt'
import CarRentalIcon from '@mui/icons-material/CarRental'
import { selectIconCar } from '../../utils/Utils'
import TabCar from './TabCar'

const moment = require('moment')

const markers: google.maps.Marker[] = []

const ControlMap = () => {
    const map = useMap()

    const [cars, setCars] = React.useState<any[]>([])

    useEffect(() => {
        if (!map) return

        map.setOptions({
            center: { lat: 42.8802049, lng: -8.5447697 },
            zoom: 10,
            styles: [
                {
                    featureType: 'poi',
                    stylers: [{ visibility: 'off' }],
                },
            ],
        })
        searchCars()
    }, [map])

    const searchCars = () => {
        carService.all((data, error) => {
            if (data && data.data) {
                var aux: any[] = []
                data.data.forEach((element) => {
                    if (element.lat && element.lng) {
                        aux.push(element)
                    }
                })
                setCars(aux)
            }
        })
    }

    const drawPoints = (cars, map) => {
        if (cars.length > 0 && map) {
            var isFirst = markers.length === 0
            markers.forEach((element) => {
                element.setMap(null)
            })
            markers.length = 0

            var bounds = new google.maps.LatLngBounds()

            cars.forEach((element) => {
                const marker = new window.google.maps.Marker({
                    map,
                    position: { lat: element.lat, lng: element.lng },
                    icon: selectIconCar(element)!,
                })
                const infowindow = new google.maps.InfoWindow({
                    content:
                        '<p style="display: flex; flex-direction: row; align-items:center; margin: 0"><span>' +
                        element.number +
                        '</span></p>',
                    ariaLabel: 'Uluru',
                })
                marker.addListener('click', () => {
                    infowindow.open({
                        anchor: marker,
                        map,
                    })
                })
                markers.push(marker)
                google.maps.event.trigger(marker, 'click')

                bounds.extend(new google.maps.LatLng(element.lat, element.lng))
            })
            if (isFirst) {
                map?.fitBounds(bounds)
            }
        }
    }

    useEffect(() => {
        drawPoints(cars, map)
    }, [cars, map])

    useEffect(() => {
        const interval = setInterval(() => searchCars(), 30000)

        return () => clearInterval(interval)
    }, [])

    return <></>
}

export default function Dashboard() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null)

    const [data, setData] = React.useState<any>(null)

    useEffect(() => {
        searchData()
    }, [])

    const searchData = () => {
        carService.all((data, error) => {
            if (data && data.data) {
                setData(data.data)
            }
        })
    }

    const eventSelect = (id) => {
        navigate('/car/edit/' + id)
    }

    return (
        <CrudEmpty i18n={'dashboard'} goback={true}>
            <div>
                <APIProvider apiKey={process.env.REACT_APP_GMAPS!}>
                    <Map
                        style={{
                            width: '100%',
                            height: 'calc(100vh - 450px)',
                        }}
                    ></Map>
                    <ControlMap />
                </APIProvider>
                <br />
                <br />
                <TabCar />
            </div>
        </CrudEmpty>
    )
}

const ImgaeIcon = styled.img`
    width: 50px;
    margin-left: 25px;
`

const PanelInfo = styled.div`
    margin: 2em;
    min-height: 220px;
    background: #83c63b77;
    padding: 1em;
    border-radius: 6px;
    font-size: 0.8em;
    line-height: 1em;

    & p {
        display: flex;
        flex-direction: row;
        align-items: center;

        & * {
            margin-right: 10px;
        }
    }

    @media (max-width: 767px) {
        margin: 0.2em;
    }
`

const PanelLegend = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: start;
    }
`
